import React from "react"

export const plans = {
  starter: {
    name: "Starter",
    price: "49",
  },
  premium: {
    name: "Premium",
    price: "99",
  },
  pro: {
    name: "Pro",
    price: "189",
  },
  ultimateplus: {
    name: "Ultimate+",
    price: "289",
  },
  enterprise: {
    name: "Enterprise",
    price: "389",
  },
}

export const planOrder = [
  "starter",
  "premium",
  "pro",
  "ultimateplus",
  "enterprise",
]

export const featureTags = {
  new: {
    name: "New",
    className: "bg-yellow-500 text-gray-900",
  },
  comingSoon: {
    name: "Coming Soon",
    className: "bg-blue-500 text-gray-900",
  },
  betaTesting: {
    name: "Beta Testing",
    className: "bg-purple-500 text-gray-900",
  },
}

export const features = [
  [
    {
      name: "Calls per month (add up to)",
      availableIn: {
        starter: "250",
        premium: "250",
        pro: "500",
        ultimateplus: "1,000",
        enterprise: (
          <a href="#enterprise-calls" className="relative">
            1,500
            <span className="absolute top-0 ml-1 text-xs text-gray-400 r-0 font-600">
              [1]
            </span>
          </a>
        ),
      },
    },
    {
      name: "Unlimited User Accounts",
      availableIn: {
        starter: true,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Mobile Apps (iOS & Android)",
      availableIn: {
        starter: true,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
  ],
  [
    {
      name: "Commission Tracking & Reporting",
      availableIn: {
        starter: true,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Photo Uploads",
      availableIn: {
        starter: true,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Account Statements",
      availableIn: {
        starter: true,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Reports (Standard)",
      availableIn: {
        starter: true,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Transport Tools",
      availableIn: {
        starter: true,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Square Integration",
      availableIn: {
        starter: true,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
  ],
  [
    {
      name: "Digital Dispatching",
      availableIn: {
        starter: false,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Out of Network Program",
      availableIn: {
        starter: false,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Impound Management",
      availableIn: {
        starter: false,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Owner/Lienholder Notice Forms",
      availableIn: {
        starter: false,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "State Impound Forms",
      availableIn: {
        starter: false,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Auto Data Direct Integration",
      availableIn: {
        starter: false,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Tow Out Management",
      availableIn: {
        starter: false,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Police Rotation (Service Provider)",
      availableIn: {
        starter: false,
        premium: true,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
  ],
  [
    {
      name: "Mapping & GPS Tracking",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Automatic Mileage Calculations",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Request Customer Location",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Web Request Forms (Standard)",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Multi-Step Tows",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Port-to-Port Hourly Rates",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Motor Club Direct Billing",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Motor Club Payment Importing",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "QuickBooks Integration (Desktop & Online)",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Call Lock & Audit Tools",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Quotes Tool",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Vehicle Locator (Customer Lookup)",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "User Check In",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Advanced Notification Service",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Private Property Tools",
      availableIn: {
        starter: false,
        premium: false,
        pro: true,
        ultimateplus: true,
        enterprise: true,
      },
    },
  ],
  [
    {
      name: "Pre-Trip Inspection Management",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Advanced Billing Tools",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Undeliverable Email Report (Statements & Invoices)",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Mass Email Statements",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Accident Reports",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Vehicle Damage Forms",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Video Uploads",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Call Chat",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Call & Driver Map Replay",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Job Progress Texts to Customers",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Customer Satisfaction Surveys",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Driver Ratings",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
    {
      name: "Predicted Call Arrival Times",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: true,
        enterprise: true,
      },
    },
  ],
  [
    {
      name: (
        <span className="relative">
          Multi-Company Support{" "}
          <span className="absolute top-0 ml-1 text-xs text-gray-400 r-0 font-600">
            [2]
          </span>
        </span>
      ),
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: false,
        enterprise: true,
      },
    },
    {
      name: "Dispatch to Subcontractor (Service Provider Plan)",
      tag: "betaTesting",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: false,
        enterprise: true,
      },
    },
    {
      name: "Auction Manager",
      tag: "betaTesting",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: false,
        enterprise: true,
      },
    },
    {
      name: "Single Sign-On (SSO)",
      tag: "comingSoon",
      availableIn: {
        starter: false,
        premium: false,
        pro: false,
        ultimateplus: false,
        enterprise: true,
      },
    },
  ],
]
