import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ContentBlock from "../components/ContentBlock"
import { ReactComponent as IconCheck } from "../icons/check-circle-1.svg"
import { plans, planOrder, featureTags, features } from "../data/pricing"
import Sticky from "react-sticky-el"

const PagePricing = () => {
  let featuresFilter = features
  return (
    <Layout>
      <PageTitle
        title="Pricing"
        description="No Setup Fees, Ever. No Contract Commitments."
      ></PageTitle>

      <ContentBlock className="mb-6 lg:mb-8 rich-text">
        <div className="max-w-4xl">
          <p>
            All plans include the same standard features such as Dispatching,
            Accounts, Mobile Applications, Reporting, and more that you need to
            run your business effectively.
          </p>
          <p>
            All Towbook plans are billed monthly and are cancellable at anytime.
          </p>
        </div>
      </ContentBlock>

      {/*
        Display plan names and pricing
      */}
      {planOrder.map((plan, index) => {
        let name = plans[plan].name
        let price = plans[plan].price
        let planFeatures = featuresFilter.flat().filter(feature => {
          let featureInPlan =
            typeof feature.availableIn !== "undefined"
              ? feature.availableIn[plan]
              : false
          return featureInPlan
        })

        featuresFilter = featuresFilter.filter((el, index) => {
          if (index === 0) return true
          return !planFeatures.includes(el)
        })
        return (
          <ContentBlock
            key={index}
            className={`lg:hidden ${index !== 0 ? "mt-6" : ""}`}
          >
            <div className="p-6 pb-3 bg-gray-900 rounded">
              <div className="flex pb-4 border-b-2 border-yellow-500 leading-200">
                <div>
                  <div className="text-xl text-yellow-500">{name}</div>
                  <div className="mt-1 text-2xl">${price}/mo</div>
                </div>
                <Link
                  to="/signup/"
                  className="block px-3 py-2 mt-auto ml-auto text-sm text-white bg-blue-500 rounded hover:bg-blue-400 font-400"
                >
                  Sign Up
                </Link>
              </div>
              {/* Iterate over every feature */}
              {planFeatures.map(({ name, tag }, featureIndex) => {
                let feature = planFeatures[featureIndex]
                let planValue = feature.availableIn[plan]
                /* Get the tag info for a given feature, if it is set */
                let tagInfo =
                  typeof feature.tag !== "undefined"
                    ? {
                        name: featureTags[tag].name,
                        className: featureTags[tag].className,
                      }
                    : false
                return (
                  <div
                    key={featureIndex}
                    className={`
                flex py-2 items-center
                ${featureIndex === 0 ? "" : "border-t border-gray-800"}
              `}
                  >
                    {/* Output the feature name and (optional) tag */}
                    <div className="flex flex-col flex-1 text-sm leading-400">
                      {tagInfo ? (
                        <div
                          className={`rounded mr-auto mb-2 px-2 py-1 uppercase font-600 leading-200 text-xs ${tagInfo.className}`}
                        >
                          {tagInfo.name}
                        </div>
                      ) : (
                        false
                      )}
                      {name}
                    </div>

                    <div className="flex justify-center flex-shrink-0 w-16 ml-8 ml-auto">
                      {/* If the feature is the boolean value 'true' for a given plan, output a check icon,otherwise output the value */}
                      {planValue === true ? (
                        <IconCheck className="w-4 h-4 text-yellow-500" />
                      ) : (
                        planValue
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </ContentBlock>
        )
      })}

      <ContentBlock className="hidden lg:block">
        <div className="relative p-8 pt-0 -mx-8 bg-gray-900 rounded-lg sticky-boundary">
          <Sticky
            topOffset={-88}
            bottomOffset={88}
            stickyStyle={{ transform: "translateY(88px)" }}
            stickyClassName="bg-gray-900 z-10"
            boundaryElement=".sticky-boundary"
            hideOnBoundaryHit={false}
          >
            <div className="flex pt-8 pb-8 border-b-2 border-yellow-500">
              <div className="flex-1"></div>
              {/*
            Display plan names and pricing
          */}
              {planOrder.map((plan, index) => {
                let name = plans[plan].name
                let price = plans[plan].price
                return (
                  <div
                    key={index}
                    className="flex-shrink-0 w-32 ml-8 text-center leading-100"
                  >
                    <div className="text-xl text-yellow-500">{name}</div>
                    <div className="mt-3 text-2xl">
                      ${price}/mo
                      <span className="text-sm align-top text-gray-400">*</span>
                    </div>
                    <Link
                      to="/signup/"
                      className="block px-3 py-2 mx-2 mt-5 text-sm text-white bg-blue-500 rounded hover:bg-blue-400 font-400"
                    >
                      Sign Up Now
                    </Link>
                  </div>
                )
              })}
            </div>
          </Sticky>

          {/* Iterate over every feature */}
          {features.map((featureGroup, groupIndex) => (
            <div key={groupIndex} className="mb-20">
              {featureGroup.map(({ name, tag, availableIn }, featureIndex) => {
                /* Get the tag info for a given feature, if it is set */
                let tagInfo =
                  typeof tag !== "undefined"
                    ? {
                        name: featureTags[tag].name,
                        className: featureTags[tag].className,
                      }
                    : false
                return (
                  <div
                    key={`${groupIndex}-${featureIndex}`}
                    className={`
                flex py-4 items-center
                ${
                  featureIndex + 1 === features[groupIndex].length
                    ? "pb-0"
                    : "border-b border-gray-800"
                }
              `}
                  >
                    {/* Output the feature name and (optional) tag */}
                    <div className="flex flex-col flex-1 text-lg leading-400">
                      {tagInfo ? (
                        <div
                          className={`rounded mr-auto mb-2 px-2 py-1 uppercase font-600 leading-200 text-xs ${tagInfo.className}`}
                        >
                          {tagInfo.name}
                        </div>
                      ) : (
                        false
                      )}
                      {name}
                    </div>

                    {/* Loop through plans, in order, and output the value for each feature */}
                    {planOrder.map((plan, planIndex) => {
                      /* Get the plan specific details for a given feature, if it is set */
                      let value =
                        typeof availableIn !== "undefined"
                          ? availableIn[plan]
                          : false
                      return (
                        <div
                          key={planIndex}
                          className="flex justify-center flex-shrink-0 w-32 ml-8"
                        >
                          {/* If the feature is the boolean value 'true' for a given plan, output a check icon,otherwise output the value */}
                          {value === true ? (
                            <IconCheck className="w-6 h-6 text-yellow-500" />
                          ) : (
                            value
                          )}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          ))}
        </div>
      </ContentBlock>
      <ContentBlock className="mt-6 lg:mt-12">
        <div
          id="enterprise-calls"
          className="relative"
          style={{ top: "-136px" }}
        ></div>
        <div className="flex max-w-lg px-6 lg:max-w-3xl lg:px-0 leading-400 lg:leading-500 mb-4">
          <div className="mr-2 text-gray-200 font-600">*</div>
          <div className="text-sm text-gray-300 lg:text-base">
            All prices are in US Dollars
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex max-w-lg px-6 lg:max-w-3xl lg:px-0 leading-400 lg:leading-500">
            <div className="mr-2 text-gray-200 font-600">[1]</div>
            <div className="text-sm text-gray-300 lg:text-base">
              Up to 1500 calls a month included. ($99 for every 1000 calls above
              1500).
            </div>
          </div>
          <div className="flex max-w-lg px-6 lg:max-w-3xl lg:px-0 leading-400 lg:leading-500">
            <div className="mr-2 text-gray-200 font-600">[2]</div>
            <div className="text-sm text-gray-300 lg:text-base">
              Link an additional company or location for $99 per month (for each
              company location).
            </div>
          </div>
        </div>
        <div className="mt-6 rich-text lg:mt-12">
          <p>
            For pricing information on multiple businesses owned by a parent
            company please call us at{" "}
            <a href="tel:+18103205063">(810) 320-5063</a>.
          </p>
        </div>
      </ContentBlock>
    </Layout>
  )
}

export default PagePricing
